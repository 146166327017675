import * as React from 'react';
import { Stack } from '@mantine/core';

import { useCheckoutSession } from 'domain/shop/repos/checkoutSession';

import { CartProduct } from './CartProduct';
import { CartState } from './CartState';
import { CheckoutSummary } from './CheckoutSummary';

export const Cart = () => {
  const { isReady, checkoutSession } = useCheckoutSession();

  return isReady ? (
    <>
      <div>
        {checkoutSession.products.length ? (
          <Stack>
            {checkoutSession.products.map((product) => (
              <CartProduct key={product.id} product={product} />
            ))}
          </Stack>
        ) : (
          <CartState.Empty />
        )}
      </div>
      <div>
        <CheckoutSummary checkoutSession={checkoutSession} />
      </div>
    </>
  ) : (
    <>
      <CartState.Loading />
      <CheckoutSummary.Skeleton />
    </>
  );
};
