import * as React from 'react';
import { Drawer, Group, rem, ScrollArea, Stack, ThemeIcon } from '@mantine/core';
import { create } from 'zustand';

import { ContentContainer, Link, Logo, Text } from 'shared/components/atoms';
import { MEALS_ITEMS, MENU_NAV, OFFER_NAV } from 'shared/consts/nav';

import * as s from './MobileMenu.module.css';

interface MenuDrawerState {
  isOpen: boolean;
  close: () => void;
  open: () => void;
}

export const useMenuDrawer = create<MenuDrawerState>()((set) => ({
  isOpen: false,
  close: () => set({ isOpen: false }),
  open: () => set({ isOpen: true }),
}));

export const MobileMenu = () => {
  const { isOpen, close } = useMenuDrawer();
  return (
    <Drawer
      size="100%"
      padding="md"
      title={<Logo />}
      hiddenFrom="sm"
      zIndex={1000000}
      opened={isOpen}
      onClose={close}
    >
      <ScrollArea h={`calc(100vh - ${rem(80)})`} mx="-md">
        <ContentContainer pb="xl">
          <nav>
            <Stack gap="xs">
              {[...OFFER_NAV, ...MENU_NAV].map(({ label, description, url }) => (
                <Link
                  key={url}
                  to={url}
                  onClick={close}
                  underline="never"
                  className={s.MobileMenu__link}
                >
                  <Text ff="heading" fw="bold">
                    {label}
                  </Text>
                  <Text fz="xs" c="gray.7">
                    {description}
                  </Text>
                </Link>
              ))}
            </Stack>
            <Stack gap={0} py="sm">
              {MEALS_ITEMS.map(({ type, label, url, Icon }) => (
                <Link
                  key={type}
                  to={url}
                  onClick={close}
                  activeClassName="active"
                  partiallyActive={true}
                  underline="never"
                  className={s.MobileMenu__link}
                >
                  <Group gap="sm">
                    <ThemeIcon className={s.MobileMenu__icon} size="sm">
                      <Icon />
                    </ThemeIcon>
                    <Text ff="heading" fz="sm">
                      {label}
                    </Text>
                  </Group>
                </Link>
              ))}
            </Stack>
          </nav>
        </ContentContainer>
      </ScrollArea>
    </Drawer>
  );
};
