import { isBrowser } from '../index';
import { Observable } from '../observable';
import { Repo } from './interface';

export class LocalStorageRepo<TValue> extends Observable<TValue | null> implements Repo<TValue> {
  constructor(public key: string) {
    super();
  }

  private get _value(): TValue | null {
    if (isBrowser) {
      const textValue = localStorage.getItem(this.key);
      if (textValue && textValue !== 'null') {
        return JSON.parse(textValue) as TValue;
      }
    }
    return null;
  }

  private set _value(value: TValue | null) {
    if (isBrowser) {
      const textValue = JSON.stringify(value);
      localStorage.setItem(this.key, textValue);
      this.notify(value);
    }
  }

  public get value() {
    return this._value;
  }

  public override(value: TValue) {
    this._value = value;
  }

  public delete() {
    this._value = null;
  }
}
