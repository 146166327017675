// All imports must be relative so gatsby-node can resolve them properly.
// All of their dependencies must use relative imports as well.
import {
  type Icon,
  IconBaguette,
  IconCookie,
  IconMilkshake,
  IconSalad,
  IconSoup,
} from '@tabler/icons-react';

import appRoutes from '../../../shared/consts/urls';
import { MealCategory, MealType } from '../schema';

interface MealConfig {
  type: MealType;
  label: MealType;
  slug: string;
  description: string;
  icon: Icon;
  categories: {
    type: MealCategory;
    label: MealCategory;
    slug: string;
  }[];
}

export class Meal {
  constructor(private _config: MealConfig) {}

  get type(): MealType {
    return this._config.type;
  }

  get label(): string {
    return this._config.label;
  }

  get slug(): string {
    return this._config.slug;
  }

  get url(): string {
    return appRoutes.recipes.meal.compose({ params: { meal: this._config.slug } });
  }

  get description(): string {
    return this._config.description;
  }

  get Icon(): Icon {
    return this._config.icon;
  }

  get categories() {
    return this._config.categories.map((category) => ({
      ...category,
      url: appRoutes.recipes.category.compose({
        params: {
          meal: this._config.slug,
          category: category.slug,
        },
      }),
    }));
  }

  public getCategory(mealCategory: MealCategory | null) {
    return this._config.categories.find(({ type }) => type === mealCategory);
  }
}

export const mealConfig: Meal[] = [
  new Meal({
    type: 'Śniadania',
    label: 'Śniadania',
    slug: 'sniadania',
    description: 'Pożywny początek dnia oraz lunche i kolacje',
    icon: IconBaguette,
    categories: [
      {
        type: 'Jajeczne',
        label: 'Jajeczne',
        slug: 'jajeczne',
      },
      {
        type: 'Omlety',
        label: 'Omlety',
        slug: 'omlety',
      },
      {
        type: 'Owsianki',
        label: 'Owsianki',
        slug: 'owsianki',
      },
      {
        type: 'Pasty',
        label: 'Pasty',
        slug: 'pasty',
      },
      {
        type: 'Placuszki',
        label: 'Placuszki',
        slug: 'placuszki',
      },
    ],
  }),
  new Meal({
    type: 'Obiady',
    label: 'Obiady',
    slug: 'obiady',
    description: 'Dania dla dwojga, na rodzinny obiad oraz do pudełka',
    icon: IconSoup,
    categories: [
      {
        type: 'Mięsne',
        label: 'Mięsne',
        slug: 'miesne',
      },
      {
        type: 'Bez mięsa',
        label: 'Bez mięsa',
        slug: 'bez-miesa',
      },
      {
        type: 'Makarony',
        label: 'Makarony',
        slug: 'makarony',
      },
      {
        type: 'Ryby',
        label: 'Ryby',
        slug: 'ryby',
      },
      {
        type: 'Zupy',
        label: 'Zupy',
        slug: 'zupy',
      },
    ],
  }),
  new Meal({
    type: 'Słodkości',
    label: 'Słodkości',
    slug: 'slodkosci',
    description: 'Zdrowa alternatywa dla fanów słodkich smaków',
    icon: IconCookie,
    categories: [
      {
        type: 'Ciasteczka',
        label: 'Ciasteczka',
        slug: 'ciasteczka',
      },
      {
        type: 'Ciasta',
        label: 'Ciasta',
        slug: 'ciasta',
      },
      {
        type: 'Desery',
        label: 'Desery',
        slug: 'desery',
      },
    ],
  }),
  new Meal({
    type: 'Sałatki',
    label: 'Sałatki',
    slug: 'salatki',
    description: 'Połączenie lekkich i zdrowych warzyw z ciekawymi dodatkami',
    icon: IconSalad,
    categories: [],
  }),
  new Meal({
    type: 'Smoothies',
    label: 'Smoothies',
    slug: 'smoothies',
    description: 'Pożywne i szybkie posiłki do picia',
    icon: IconMilkshake,
    categories: [],
  }),
];

export const getMeal = (mealType: MealType) => {
  const meal = mealConfig.find((meal) => meal.type === mealType);
  if (!meal) throw new Error(`Meal details requesteed for unsupported meal type: ${mealType}.`);
  return meal;
};
