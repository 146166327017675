import * as React from 'react';
import { rem } from '@mantine/core';
import { useMutation } from '@tanstack/react-query';

import { Button } from 'shared/components/atoms';
import { ID } from 'shared/types';
import { useProductVariantContext } from 'domain/shop/components';
import { cart } from 'domain/shop/entities/Cart';
import { useCheckoutSession } from 'domain/shop/repos/checkoutSession';

import { useCartDrawer } from '../CartDrawer';

const messages = {
  cta: 'Dodaj do koszyka',
  unavailable: 'Niedostępny',
};

export function ProductVariantButton() {
  const { isReady } = useCheckoutSession();
  const { selectedOptions, variants } = useProductVariantContext();
  const open = useCartDrawer((state) => state.open);

  const addProduct = useMutation({
    mutationFn: async (variantId: ID) => {
      await cart.addProduct({ variantId });
      open();
    },
  });

  const currentVariant = variants.find((variant) => variant.isSelected(selectedOptions));
  const isVariantAvailable = isReady && currentVariant && currentVariant.isAvailable;

  const handleAddToCart = () => {
    if (isVariantAvailable) {
      addProduct.mutate(currentVariant.shopifyId);
    }
  };
  return (
    <Button
      type="button"
      onClick={handleAddToCart}
      isLoading={addProduct.isPending}
      disabled={!isVariantAvailable}
      miw={rem(250)}
    >
      {currentVariant?.isAvailable ? messages.cta : messages.unavailable}
      {/* When using isVariantAvailable condition instead, then while initializing the checkout button label says "unavaliable" which may be confusing. */}
    </Button>
  );
}
