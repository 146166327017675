import { Edges, Tuple } from 'shared/types';

export const modulo = (n: number, m: number) => {
  return ((n % m) + m) % m;
};

export const truncateText = (text: string, numberOfChars = 160) => {
  let truncatedText = '';
  if (text.length <= numberOfChars) {
    truncatedText = text;
  } else {
    truncatedText = text.substring(0, numberOfChars).trim() + '...';
  }
  return truncatedText;
};

export const nbspScript = (text: string) => {
  const input = text.split(' ');
  let output = input[input.length - 1];
  for (let index = 0; index < input.length; index++) {
    const reversedIndex = input.length - index - 1;
    const word = input[reversedIndex];
    if (reversedIndex !== input.length - 1) {
      const isAlphanumeric = /\w/.test(word);
      if (!isAlphanumeric || word.length > 1) {
        output = `${word} ${output}`;
      } else {
        output = `${word}\u00A0${output}`;
      }
    }
  }
  return output;
};

export function ifVariant<TVariant, TLength extends number>(
  variants: Tuple<TVariant, TLength>,
  defaultVariant = 0,
) {
  return function <TValue>(
    current: TVariant | undefined | null,
    values: Tuple<TValue, TLength>,
  ): TValue {
    let result = values[defaultVariant];
    variants.forEach((variant, index) => {
      if (variant === current) {
        result = values[index];
      }
    });
    return result;
  };
}

export const flattenEdges = <T>({ edges }: Edges<T>): T[] => edges.flatMap(({ node }) => node);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const assertUnreachable = (x: never): never => {
  throw new Error('Unrechable');
};

export const composeHandlers = (...handlers: unknown[]) => {
  return (...args: unknown[]) => {
    handlers.forEach((handler) => {
      if (typeof handler === 'function') {
        handler(...args);
      }
    });
  };
};

export const isBrowser = typeof window !== 'undefined';
