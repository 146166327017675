import * as React from 'react';
import { rem } from '@mantine/core';

export interface IconProps extends React.ComponentPropsWithoutRef<'svg'>, React.PropsWithChildren {
  size?: number | string;
}

export const Svg = (props: IconProps) => {
  const { children, size, style, ...rest } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: rem(size), height: rem(size), ...style }}
      {...rest}
    >
      {children}
    </svg>
  );
};
