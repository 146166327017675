import * as React from 'react';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { Card, Group, SimpleGrid, Stack, UnstyledButton } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import { ButtonOutlined, Img, Modal, Text } from 'shared/components/atoms';
import { RichText } from 'shared/schemas';

import { OpinionHeader, OpinionHeaderProps } from './OpinionHeader';

import * as s from './OpinionCard.module.css';

const messages = {
  readMore: 'Pokaż więcej',
  close: 'Zamknij',
};

interface OpinionCardProps extends Omit<OpinionHeaderProps, 'image'> {
  body: RichText;
  extraImages?: IGatsbyImageData[];
}

export const OpinionCard = (props: OpinionCardProps) => {
  const { body, extraImages, ...headerProps } = props;
  const [isOpen, { close, open }] = useDisclosure();
  return (
    <>
      <Card component="article" shadow="xs" radius="md">
        <Stack gap="sm">
          <OpinionHeader {...headerProps} />
          <Text nbsp lineClamp={6}>
            {body.text}
          </Text>
          <Group justify="end">
            <UnstyledButton onClick={open} className={s.OpinionHeader__button}>
              {messages.readMore}
            </UnstyledButton>
          </Group>
        </Stack>
      </Card>

      <Modal opened={isOpen} onClose={close} size="lg" title={<OpinionHeader {...headerProps} />}>
        <Stack align="center" gap="lg">
          <Text>{body.text}</Text>
          {extraImages && extraImages.length > 0 && (
            <SimpleGrid cols={{ base: 1, xs: 2, sm: 3 }}>
              {extraImages.map((image, index) => (
                <div key={index}>
                  <Img image={image} alt="" />
                </div>
              ))}
            </SimpleGrid>
          )}
          <ButtonOutlined onClick={close}>{messages.close}</ButtonOutlined>
        </Stack>
      </Modal>
    </>
  );
};
