import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import Cookies from 'js-cookie';

import { Repo } from 'shared/utils/repo/interface';

import { gdprConsent } from './gdprConsent';
import { GDPRConsent } from './types';

class Analytics {
  public cookies = {
    // cookie names must be the same as in the gatsby-config
    facebookPixel: 'jzzr_fbq',
    googleAnalytics: 'jzzr_ga',
    googleTagManager: 'jzzr_gtm',
  };
  constructor(private _consent: Repo<GDPRConsent>) {
    if (!this._consent.value) {
      this._consent.override({
        accepted: false,
        replied: false,
      });
    }
  }

  public accept(location: Location) {
    Cookies.set(this.cookies.facebookPixel, 'true', { sameSite: 'Strict' });
    Cookies.set(this.cookies.googleAnalytics, 'true', { sameSite: 'Strict' });
    Cookies.set(this.cookies.googleTagManager, 'true', { sameSite: 'Strict' });

    // initialize using gatsby-plugin-gdpr-cookies
    initializeAndTrack(location);

    this._consent.override({
      accepted: true,
      replied: true,
    });
  }

  public reject() {
    Cookies.set(this.cookies.facebookPixel, 'false', { sameSite: 'Strict' });
    Cookies.set(this.cookies.googleAnalytics, 'false', { sameSite: 'Strict' });
    Cookies.set(this.cookies.googleTagManager, 'false', { sameSite: 'Strict' });

    this._consent.override({
      accepted: false,
      replied: true,
    });
  }

  get accepted() {
    return Boolean(this._consent.value?.accepted);
  }

  get replied() {
    return Boolean(this._consent.value?.replied);
  }
}

export const analytics = new Analytics(gdprConsent);
