import * as React from 'react';
import { PageProps } from 'gatsby';
import { AppShell } from '@mantine/core';

import { CookiePopup } from 'shared/components/molecules';
import { Footer, Header, OfferPromote } from 'shared/components/organisms';

export interface LayoutConfig {
  disableOfferPromote?: boolean;
}

export const Layout = (props: PageProps<unknown, LayoutConfig>) => {
  const {
    children,
    pageContext: { disableOfferPromote = false },
  } = props;
  return (
    <AppShell withBorder={false} header={{ height: '80px' }}>
      <AppShell.Header bg="none">
        <Header />
      </AppShell.Header>

      <AppShell.Main>
        {children}
        {disableOfferPromote ? null : <OfferPromote />}
      </AppShell.Main>

      <Footer />

      <CookiePopup />
    </AppShell>
  );
};

export default Layout;
