import * as React from 'react';

import { StateRepo } from 'shared/utils/repo/StateRepo';

import { CheckoutSession } from '../types';

export const checkoutSession = new StateRepo<CheckoutSession>();

type CheckoutSessionContext =
  | {
      isReady: true;
      checkoutSession: CheckoutSession;
    }
  | {
      isReady: false;
      checkoutSession: null;
    };

const CheckoutSessionContext = React.createContext<CheckoutSessionContext>({
  isReady: false,
  checkoutSession: null,
});

export const CheckoutSessionContextProvider = (props: { children: React.ReactNode }) => {
  const [, forceRender] = React.useReducer((x) => x + 1, 0);

  React.useEffect(() => {
    const subscription = checkoutSession.subscribe(forceRender);
    return () => checkoutSession.unsubscribe(subscription);
  }, []);

  const value: CheckoutSessionContext = checkoutSession.value
    ? {
        isReady: true,
        checkoutSession: checkoutSession.value,
      }
    : {
        isReady: false,
        checkoutSession: null,
      };

  return <CheckoutSessionContext.Provider {...props} value={value} />;
};

export const useCheckoutSession = () => {
  return React.useContext(CheckoutSessionContext);
};
