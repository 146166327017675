import * as React from 'react';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { Avatar, Group, Stack } from '@mantine/core';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { Stars, Text } from 'shared/components/atoms';

import 'dayjs/locale/pl';

dayjs.locale('pl');
dayjs.extend(relativeTime);

export interface OpinionHeaderProps {
  author: string;
  stars: number;
  authorImage: IGatsbyImageData | null;
  publishedAt: string;
}

export const OpinionHeader = (props: OpinionHeaderProps) => {
  const { author, authorImage, stars, publishedAt } = props;
  return (
    <Group component="header">
      <Avatar src={authorImage?.images.fallback?.src} alt={author} size="lg" color="primary" />
      <Stack gap="xxs">
        <Text ff="heading" fw="bold" c="gray.9">
          {author}
        </Text>
        <Stars rating={stars} max={5} />
        <Text fz="xs" c="gray.7">
          {/* Show text up to 6 months from comment posted, after 6 months show the date. */}
          {dayjs().subtract(6, 'months').isBefore(dayjs(publishedAt))
            ? dayjs(publishedAt).fromNow()
            : dayjs(publishedAt).format('DD.MM.YYYY')}
        </Text>
      </Stack>
    </Group>
  );
};
