import * as React from 'react';
import { Box, BoxProps, rem } from '@mantine/core';

import * as s from './Dot.module.css';

interface DotProps extends BoxProps {
  size?: number;
}

export const Dot = (props: DotProps) => {
  const { size = 4, bg = 'gray.7' } = props;
  return <Box className={s.Dot} bg={bg} w={rem(size)} h={rem(size)} {...props} />;
};
