import * as React from 'react';
import { createPolymorphicComponent, Flex, MantineColor } from '@mantine/core';

import { Line, Text } from 'shared/components/atoms';

import * as s from './TitleSubline.module.css';

export interface TitleSublineProps extends React.PropsWithChildren {
  title?: string;
  lineSize?: number;
  align?: 'left' | 'center';
  color?: MantineColor;
}

export const TitleSubline = createPolymorphicComponent<'h1', TitleSublineProps>(
  React.forwardRef<HTMLHeadingElement, TitleSublineProps>((props, ref) => {
    const { children, title, lineSize = 8, align = 'left', color = 'primary', ...rest } = props;
    const isCenter = align === 'center';

    return (
      <Flex
        direction={isCenter ? 'column-reverse' : 'row'}
        justify={isCenter ? 'center' : 'flex-start'}
        gap="xs"
        align="center"
      >
        <Line size={lineSize} color={color} />
        <Text ref={ref} className={s.TitleSubline} c={color} {...rest}>
          {title || children}
        </Text>
      </Flex>
    );
  }),
);
