import * as React from 'react';
import { Card, CardProps } from '@mantine/core';
import clsx from 'clsx';

import { Link } from 'shared/components/atoms';

import { CardLinkImage } from './CardLinkImage';
import { CardLinkTitle } from './CardLinkTitle';

import * as s from './CardLink.module.css';

interface CardLinkProps extends CardProps {
  to: string;
}

export const CardLink = (props: CardLinkProps) => {
  const { children, className, to, ...rest } = props;
  return (
    <Link to={to} className={s.CardLink__link}>
      <Card component="article" radius="md" className={clsx(s.CardLink__card, className)} {...rest}>
        {children}
      </Card>
    </Link>
  );
};

CardLink.Section = Card.Section;
CardLink.Title = CardLinkTitle;
CardLink.Image = CardLinkImage;
