import * as React from 'react';
import { Box, BoxProps, MantineColor, rem } from '@mantine/core';

interface LineProps extends BoxProps {
  size?: number;
  color?: MantineColor;
}

export const Line = (props: LineProps) => {
  const { size = 8, color = 'primary', ...rest } = props;
  return <Box bg={color} h={4} w={rem(size * 8)} {...rest} />;
};
