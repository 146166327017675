import * as React from 'react';
import { Table } from '@mantine/core';

import * as s from './MenuTable.module.css';

interface MenuTableProps {
  head: React.ReactNode[];
  body: React.ReactNode[][];
  minWidth?: number;
}

export function MenuTable(props: MenuTableProps) {
  const { head, body, minWidth = 800 } = props;

  return (
    <Table.ScrollContainer minWidth={minWidth}>
      <Table striped withRowBorders={false}>
        <Table.Thead>
          <Table.Tr>
            {head.map((col, index) => (
              <Table.Th key={`head-${index}`} scope="col" className={s.Table_th}>
                {col}
              </Table.Th>
            ))}
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {body.map((row, index) => (
            <Table.Tr key={`row-${index}`} className={s.Table_tr}>
              {row.map((item, index) => (
                <Table.Td key={`col-${index}`} className={s.Table_td}>
                  {item}
                </Table.Td>
              ))}
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
    </Table.ScrollContainer>
  );
}
