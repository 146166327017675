import {
  ActionIcon,
  Container,
  createTheme,
  Divider,
  em,
  List,
  MantineColorsTuple,
  rem,
  Select,
  ThemeIcon,
} from '@mantine/core';

const PRIMARY_COLOR: MantineColorsTuple = [
  '#FFF8FE', // 0
  '#FFF8FE', // 1
  '#FFF2F9', // 2
  '#FFE3EC', // 3
  '#ffb0c8', // 4
  '#FF9CBA', // 5
  '#FF86AB', // 6
  '#FB6090', // 7 main
  '#f25f8b', // 8
  '#d64873', // 9
];

const SECONDARY_COLOR: MantineColorsTuple = [
  '#F4FEFF', // 0
  '#E9FDFF', // 1
  '#D3F4F7', // 2
  '#C1F0F3', // 3
  '#B3EDF1', // 4
  '#9DEAF0', // 5
  '#8AE6ED', // 6 main
  '#64DBE3', // 7
  '#42B3BE', // 8
  '#2A9EAB', // 9
];

const GRAY_COLOR: MantineColorsTuple = [
  '#FAFBFD', // 0
  '#F8F9FB', // 1
  '#ECEEF1', // 2
  '#E1E3E5', // 3
  '#CBCDD0', // 4
  '#AFB1B3', // 5
  '#8E8F91', // 6
  '#7C7E7F', // 7 main
  '#4C4D4E', // 8
  '#3E4040', // 9
];

const TETANY_COLOR: MantineColorsTuple = [
  '#e3fafc',
  '#c5f6fa',
  '#99e9f2',
  '#66d9e8',
  '#3bc9db',
  '#22b8cf',
  '#15aabf',
  '#1098ad',
  '#0c8599',
  '#0b7285',
];

const CONTAINER_SIZES: Record<string, string> = {
  xxs: em('350px'), // 21.875em
  xs: em('400px'), // 25em
  sm: em('700px'), // 43.75em
  md: em('900px'), // 56.25em
  lg: em('1104px'), // 69em
  xl: em('1408px'), // 88em
};

export const theme = createTheme({
  cursorType: 'pointer',
  fontFamily: 'Open Sans, sans-serif',
  headings: {
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: 'bold',
  },
  white: '#ffffff',
  black: '#303131',
  primaryShade: 7,
  colors: {
    primary: PRIMARY_COLOR,
    secondary: SECONDARY_COLOR,
    gray: GRAY_COLOR,
    tetany: TETANY_COLOR,
  },
  primaryColor: 'primary',
  spacing: {
    xxs: '0.25rem',
    xs: '0.5rem',
    lg: '1.5rem',
  },
  breakpoints: CONTAINER_SIZES,
  components: {
    Select: Select.extend({
      defaultProps: {
        radius: 'md',
        variant: 'filled',
        comboboxProps: {
          offset: 4,
          radius: 'md',
        },
      },
    }),
    Divider: Divider.extend({
      defaultProps: {
        color: 'gray.2',
      },
    }),
    List: List.extend({
      defaultProps: {
        listStyleType: 'none',
      },
    }),
    ThemeIcon: ThemeIcon.extend({
      defaultProps: {
        variant: 'transparent',
      },
    }),
    ActionIcon: ActionIcon.extend({
      defaultProps: {
        variant: 'subtle',
      },
    }),
    Container: Container.extend({
      defaultProps: {
        size: 'lg',
      },
      vars: (_, { size, fluid }) => ({
        root: {
          '--container-size': fluid
            ? '100%'
            : size !== undefined && size in CONTAINER_SIZES
              ? CONTAINER_SIZES[size]
              : rem(size),
        },
      }),
    }),
  },
  // ... other theme override properties
});
