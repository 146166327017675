type Observer<TData> = (data: TData) => void;

export class Observable<TData> {
  private _observers: Observer<TData>[] = [];

  protected notify(data: TData) {
    this._observers.forEach((action) => {
      action(data);
    });
  }

  public subscribe(observer: Observer<TData>) {
    this._observers.push(observer);
    return observer;
  }

  public unsubscribe(observer: Observer<TData>) {
    const removeIndex = this._observers.findIndex((obs) => {
      return observer === obs;
    });

    if (removeIndex !== -1) {
      this._observers = this._observers.slice(removeIndex, 1);
    }
  }
}
