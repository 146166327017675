import * as React from 'react';
import { MantineProvider } from '@mantine/core';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { theme } from 'shared/styles/theme';
import { isBrowser } from 'shared/utils';
import { checkout } from 'domain/shop/entities/Checkout';
import { CheckoutSessionContextProvider } from 'domain/shop/repos/checkoutSession';

const queryClient = new QueryClient();

interface ProvidersProps {
  children: React.ReactNode;
}

export const Providers = (props: ProvidersProps) => {
  const { children } = props;

  React.useEffect(() => {
    if (isBrowser) {
      // Must fetch it here due to race condition with page hydration.
      // It was initially done onClientEntry in gatsby-browser and caused the issue.
      checkout.retrieveSession();
    }
  }, []);

  return (
    <CheckoutSessionContextProvider>
      <QueryClientProvider client={queryClient}>
        <MantineProvider theme={theme}>{children}</MantineProvider>
      </QueryClientProvider>
    </CheckoutSessionContextProvider>
  );
};

export default Providers;
