import { Observable } from '../observable';
import { Repo } from './interface';

export class StateRepo<TValue> extends Observable<TValue | null> implements Repo<TValue | null> {
  private _value: TValue | null = null;

  constructor() {
    super();
  }

  private set value(value: TValue | null) {
    this._value = value;
    this.notify(this.value);
  }

  public get value() {
    return this._value;
  }

  public override(value: TValue): void {
    this.value = value;
  }

  public delete(): void {
    this.value = null;
  }
}
