import * as React from 'react';

import { Text } from 'shared/components/atoms';

import { useProductVariantContext } from './ProductVariant';

export const ProductVariantPrice = () => {
  const { selectedOptions, variants } = useProductVariantContext();
  const currentVariant = variants.find((variant) => variant.isSelected(selectedOptions));

  return currentVariant ? (
    <>
      <Text ff="heading" fz="24px" fw="bold">
        {currentVariant.price.format()}
      </Text>
    </>
  ) : null;
};
