import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Group, rem } from '@mantine/core';
import dayjs, { Dayjs } from 'dayjs';
import {
  FacebookIcon,
  FacebookShareButton,
  PinterestIcon,
  PinterestShareButton,
  TwitterShareButton,
  XIcon,
} from 'react-share';

import { ButtonIcon, Text } from 'shared/components/atoms';
import { DATE_FORMAT } from 'shared/consts';

type SiteMeta = {
  url?: string;
  twitterNick?: string;
};

export interface ShareButtonsProps {
  url: string;
  title: string;
  date: string | Dayjs;
  image?: string;
  description?: string;
  size?: number;
  color?: string;
}

export const ShareButtons = ({
  url,
  image,
  title,
  description,
  date,
  color = 'grey600',
  size = 3,
}: ShareButtonsProps) => {
  const data = useStaticQuery<{ site: { siteMetadata: SiteMeta } }>(graphql`
    query Metadata {
      site {
        siteMetadata {
          url
          twitterNick
        }
      }
    }
  `);
  const text = `${title}\n${description}\n`;
  const href = data.site.siteMetadata.url + url;

  return (
    <>
      <Group justify="space-between" align="center">
        <Group align="center" gap="md">
          <ButtonIcon component={FacebookShareButton} url={href} title={text} size={rem(size * 8)}>
            <FacebookIcon round />
          </ButtonIcon>
          <ButtonIcon
            component={TwitterShareButton}
            url={href}
            title={text}
            via={data.site.siteMetadata.twitterNick}
            size={rem(size * 8)}
          >
            <XIcon round />
          </ButtonIcon>
          {image && (
            <ButtonIcon
              component={PinterestShareButton}
              title={text}
              url={href}
              media={image}
              description={title}
              size={rem(size * 8)}
            >
              <PinterestIcon round />
            </ButtonIcon>
          )}
        </Group>
        <Text component="time" c={color}>
          {dayjs(date).format(DATE_FORMAT)}
        </Text>
      </Group>
    </>
  );
};
