import * as React from 'react';
import {
  ActionIcon,
  type ActionIconProps,
  createPolymorphicComponent,
  ElementProps,
} from '@mantine/core';
import clsx from 'clsx';

import * as s from './ButtonIcon.module.css';

interface ButtonIconProps extends ActionIconProps, Omit<ElementProps<'button'>, 'color'> {
  title: string;
}

export const ButtonIcon = createPolymorphicComponent<'button', ButtonIconProps>(
  React.forwardRef<HTMLButtonElement, ButtonIconProps>((props, ref) => {
    const { className, title, ...rest } = props;
    return (
      <ActionIcon
        ref={ref}
        className={clsx(className, s.ButtonIcon)}
        title={title}
        aria-label={title}
        {...rest}
      />
    );
  }),
);
