import * as React from 'react';
import { Indicator } from '@mantine/core';
import { IconShoppingBag } from '@tabler/icons-react';

import { useCheckoutSession } from 'domain/shop/repos/checkoutSession';

import { Text } from '../../../../shared/components/atoms';

export const CartIcon = () => {
  const { isReady, checkoutSession } = useCheckoutSession();

  const productsInCartCout = isReady
    ? checkoutSession.products.reduce((total, product) => total + product.quantity, 0)
    : 0;

  return (
    <Indicator
      label={
        <Text ff="heading" fz="xs" fw="bold">
          {productsInCartCout}
        </Text>
      }
      size={16}
      disabled={productsInCartCout === 0}
    >
      <IconShoppingBag />
    </Indicator>
  );
};
