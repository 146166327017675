import * as React from 'react';
import { Container, ContainerProps } from '@mantine/core';
import clsx from 'clsx';

import * as s from './ContentContainer.module.css';

interface ContentContainerProps extends ContainerProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: any;
}

export const ContentContainer = (props: ContentContainerProps) => {
  const { className, ...rest } = props;
  return <Container {...rest} className={clsx(s.ContentContainer, className)} />;
};
