import { ID } from 'shared/types';
import { Repo } from 'shared/utils/repo/interface';

import { checkoutId } from '../repos/checkoutId';
import { checkoutSession } from '../repos/checkoutSession';
import { CheckoutSession } from '../types';
import { CheckoutProvider } from './CheckoutProvider/interface';
import { shopifyCheckoutProvider } from './CheckoutProvider/Shopify';

export class Cart {
  constructor(
    private _checkoutProvider: CheckoutProvider,
    private _checkoutId: Repo<ID>,
    private _checkoutSession: Repo<CheckoutSession>,
  ) {}

  public get isEmpty(): boolean {
    const checkout = this._checkout;
    return checkout.products.length === 0;
  }

  public hasProduct(variantId: ID): boolean {
    const checkout = this._checkout;
    const productIndex = checkout.products.findIndex((prod) => prod.variant.id === variantId);
    return productIndex > -1;
  }

  public async addProduct(product: { variantId: ID }) {
    try {
      // Abort early if product has been already added to the cart
      const isInCart = this.hasProduct(product.variantId);
      if (isInCart) {
        return;
      }
      // Add the product to the checkout
      const checkout = await this._checkoutProvider.addCheckoutProduct(this._sessionId, {
        variant: { id: product.variantId },
      });
      // Update session
      this._checkoutSession.override(checkout);
    } catch (error: unknown) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw error;
    }
  }

  public async removeProduct(productId: ID) {
    try {
      // Remove the product from the checkout
      const checkout = await this._checkoutProvider.removeCheckoutProduct(
        this._sessionId,
        productId,
      );
      // Update session
      this._checkoutSession.override(checkout);
    } catch (error: unknown) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw error;
    }
  }

  private get _checkout(): CheckoutSession {
    const checkout = this._checkoutSession.value;
    if (checkout) {
      return checkout;
    } else {
      throw new Error('Checkout session does not exist.');
    }
  }

  private get _sessionId(): ID {
    const sessionId = this._checkoutId.value;
    if (sessionId) {
      return sessionId;
    } else {
      throw new Error('Checkout session ID does not exist.');
    }
  }
}

export const cart = new Cart(shopifyCheckoutProvider, checkoutId, checkoutSession);
