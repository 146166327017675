import * as React from 'react';
import { ElementProps } from '@mantine/core';

import * as s from './AnchorTarget.module.css';

export const AnchorTarget = (props: React.PropsWithChildren<ElementProps<'div'>>) => {
  const { children, ...rest } = props;
  return (
    <div {...rest} className={s.AnchorTarget}>
      {children}
    </div>
  );
};
