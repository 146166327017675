import { graphql, useStaticQuery } from 'gatsby';

interface SiteMetadata {
  url?: string;
  title?: string;
  description?: string;
  keywords?: string;
  twitterNick?: string;
  author?: string;
}

export const useSiteMetadata = (): SiteMetadata => {
  const data = useStaticQuery<{ site: { siteMetadata: SiteMetadata } }>(graphql`
    {
      site {
        siteMetadata {
          url
          title
          description
          keywords
          twitterNick
          author
        }
      }
    }
  `);
  return data.site.siteMetadata;
};
