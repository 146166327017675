import { ID } from 'shared/types';
import { Repo } from 'shared/utils/repo/interface';

import { checkoutId } from '../repos/checkoutId';
import { checkoutSession } from '../repos/checkoutSession';
import { CheckoutSession } from '../types';
import { CheckoutProvider } from './CheckoutProvider/interface';
import { shopifyCheckoutProvider } from './CheckoutProvider/Shopify';

export class Checkout {
  constructor(
    private _checkoutProvider: CheckoutProvider,
    private _checkoutId: Repo<ID>,
    private _checkoutSession: Repo<CheckoutSession>,
  ) {}

  public async retrieveSession(): Promise<void> {
    // check localStorage and server
    const sessionId = this._checkoutId.value;
    if (sessionId) {
      try {
        const existingCheckout = await this._checkoutProvider.getCheckoutSession(sessionId);
        // check if session expired
        if (!existingCheckout || existingCheckout.isExpired) {
          // create new session
          const newCheckout = await this._checkoutProvider.createCheckoutSession();
          this._checkoutId.override(newCheckout.id);
          this._checkoutSession.override(newCheckout);
        } else {
          this._checkoutSession.override(existingCheckout);
        }
      } catch (error: unknown) {
        // eslint-disable-next-line no-console
        console.error(error);
        throw error;
      }
    } else {
      try {
        const newCheckout = await this._checkoutProvider.createCheckoutSession();
        this._checkoutId.override(newCheckout.id);
        this._checkoutSession.override(newCheckout);
      } catch (error: unknown) {
        // eslint-disable-next-line no-console
        console.error(error);
        throw error;
      }
    }
  }

  public redirectToCheckout(): void {
    const checkoutSession = this._checkoutSession.value;
    if (checkoutSession) {
      // redirect to the checkout URL
      window.location.href = checkoutSession.checkoutUrl;
    }
  }

  public async applyDiscount(discountCode: string): Promise<void> {
    const sessionId = this._checkoutId.value;
    if (sessionId) {
      try {
        const newCheckout = await this._checkoutProvider.addCheckoutDiscount(
          sessionId,
          discountCode,
        );
        this._checkoutSession.override(newCheckout);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        throw error;
      }
    }
  }

  public async removeDiscount(): Promise<void> {
    const sessionId = this._checkoutId.value;
    if (sessionId) {
      try {
        const newCheckout = await this._checkoutProvider.removeCheckoutDiscount(sessionId);
        this._checkoutSession.override(newCheckout);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        throw error;
      }
    }
  }
}

export const checkout = new Checkout(shopifyCheckoutProvider, checkoutId, checkoutSession);
