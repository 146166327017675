import * as React from 'react';
import type { IGatsbyImageData } from 'gatsby-plugin-image';
import { AspectRatio, Grid } from '@mantine/core';

import { Img } from 'shared/components/atoms';

import * as s from './ImagesGrid.module.css';

interface ImagesGridProps {
  images: {
    image: IGatsbyImageData;
  }[];
}

export const ImagesGrid = (props: ImagesGridProps) => {
  const { images } = props;
  const [activeImage, setActiveImage] = React.useState(0);

  const handleChange = (image: number) => () => {
    setActiveImage(image);
  };

  return (
    <Grid columns={4}>
      <Grid.Col span={4}>
        <AspectRatio ratio={9 / 11.8} className={s.ImagesGrid__thumbnail}>
          <Img image={images[activeImage]?.image} alt="" objectPosition="top" />
        </AspectRatio>
      </Grid.Col>

      {images.map(({ image }, index) => (
        <Grid.Col key={index} span={1}>
          <AspectRatio
            ratio={1}
            className={s.ImagesGrid__thumbnail}
            onMouseEnter={handleChange(index)}
            onTouchStart={handleChange(index)}
          >
            <Img image={image} alt="" />
          </AspectRatio>
        </Grid.Col>
      ))}
    </Grid>
  );
};
