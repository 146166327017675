import appRoutes from 'shared/consts/urls';
import { mealConfig } from 'domain/recipes/config';

interface NavItem {
  label: string;
  description: string;
  url: string;
}

export const OFFER_NAV: NavItem[] = [
  {
    label: 'Współpraca',
    description: 'Indywidualna współpraca dietetyczna online',
    url: appRoutes.offer.collab.compose(),
  },
  {
    label: 'Konsultacje',
    description: 'Konsultacje dietetyczne online',
    url: appRoutes.offer.consultation.compose(),
  },
  {
    label: 'Dieta w ciąży',
    description: 'Świadoma dieta w ciąży',
    url: appRoutes.offer.dietPregnant.compose(),
  },
  {
    label: 'Gotowe diety',
    description: 'Jadłospisy gotowe do zakupu',
    url: appRoutes.offer.diets.index.compose(),
  },
];

export const MENU_NAV: NavItem[] = [
  {
    label: 'Opinie',
    description: 'Sprawdź co mówią osoby, z którymi współpracowałam',
    url: appRoutes.opinions.index.compose(),
  },
  {
    label: 'Newsletter',
    description: 'Bądź na bierząco i zyskaj dodatkowe korzyści',
    url: '/#newsletter',
  },
  {
    label: 'Blog',
    description: 'Wskazówki i porady żywnieniowe',
    url: appRoutes.blog.index.compose(),
  },
  {
    label: 'Przepisy',
    description: 'Zdrowe i pyszne posiłki na każdą okazję',
    url: appRoutes.recipes.index.compose(),
  },
];

export const MEALS_ITEMS = mealConfig.map((meal) => ({
  type: meal.type,
  label: meal.label,
  url: meal.url,
  description: meal.description,
  Icon: meal.Icon,
  categories: meal.categories,
}));

export type MealItem = (typeof MEALS_ITEMS)[number];
