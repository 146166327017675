import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Group, rem } from '@mantine/core';
import * as z from 'zod';

import { ButtonIcon, Link } from 'shared/components/atoms';
import { Facebook, Instagram } from 'shared/components/icons';

import * as s from './SocialMedia.module.css';

const MetaData = z.object({
  siteMetadata: z.object({
    facebook: z.string(),
    instagram: z.string(),
  }),
});

export const SocialMedia = () => {
  const data = useStaticQuery(graphql`
    query SocialUrlsQuery {
      site {
        siteMetadata {
          facebook
          instagram
        }
      }
    }
  `);
  const parsed = MetaData.parse(data.site);
  const SOCIAL_ITEMS = [
    {
      name: 'Facebook',
      logo: Facebook,
      url: parsed.siteMetadata.facebook,
    },
    {
      name: 'Instagram',
      logo: Instagram,
      url: parsed.siteMetadata.instagram,
    },
  ];

  return (
    <Group>
      {SOCIAL_ITEMS.map(({ name, url, logo: Logo }) => (
        <ButtonIcon
          key={url}
          component={Link}
          to={url}
          title={name}
          size="md"
          variant="transparent"
          className={s.SocialMedia__icon}
        >
          <Logo style={{ width: rem(28), height: rem(28) }} />
        </ButtonIcon>
      ))}
    </Group>
  );
};
