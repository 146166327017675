import * as React from 'react';
import { Group, HoverCard, Stack, ThemeIcon } from '@mantine/core';

import { Link, Text } from 'shared/components/atoms';
import { MEALS_ITEMS } from 'shared/consts/nav';

import * as s from './RecipesNavCard.module.css';

export const RecipesNavCard = (props: React.PropsWithChildren) => {
  const { children } = props;

  return (
    <HoverCard openDelay={0} radius="md" shadow="md">
      <HoverCard.Target>{children}</HoverCard.Target>
      <HoverCard.Dropdown className={s.RecipeNavCard}>
        <Stack gap={8}>
          {MEALS_ITEMS.map(({ label, url, Icon, description }) => (
            <Link
              key={url}
              to={url}
              underline="never"
              className={s.RecipeNavCard__link}
              activeClassName="active"
            >
              <Group align="flex-start" gap="sm" wrap="nowrap">
                <ThemeIcon className={s.RecipeNavCard__icon}>
                  <Icon />
                </ThemeIcon>
                <div>
                  <Text size="sm" ff="heading" fw="bold">
                    {label}
                  </Text>
                  <Text size="xs" c="gray">
                    {description}
                  </Text>
                </div>
              </Group>
            </Link>
          ))}
        </Stack>
      </HoverCard.Dropdown>
    </HoverCard>
  );
};
