export class Price {
  private _minPrice: number;
  private _maxPrice: number;

  constructor(min: number, max = min) {
    if (max < min) {
      throw new Error('Max price connot be lower than min price.');
    }
    this._minPrice = Number(min);
    this._maxPrice = Number(max);
  }

  get min(): number {
    return this._minPrice;
  }

  get max(): number {
    return this._maxPrice;
  }

  get isRange() {
    return this.min !== this.max;
  }

  public format() {
    const intl = new Intl.NumberFormat('pl-PL', { style: 'currency', currency: 'PLN' });
    if (this.isRange) {
      return `od ${intl.format(this.min)}`;
    } else {
      return intl.format(this.min);
    }
  }

  public subtract(...minus: Price[]): Price {
    // TODO: how to subtract ranges
    return new Price(this.min - minus.reduce((total, current) => total + current.min, 0));
  }

  public add(...plus: Price[]): Price {
    // TODO: how to add ranges
    return new Price(plus.reduce((total, current) => total + current.min, 0) + this.min);
  }

  public isSame(compareTo: Price): boolean {
    return compareTo.min === this.min && compareTo.max === this.max;
  }

  public isLowerThen(compareTo: Price): boolean {
    return this.min < compareTo.min;
  }

  public isBiggerThen(compareTo: Price): boolean {
    return this.max > compareTo.max;
  }
}
