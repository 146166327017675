import * as React from 'react';
import { Card, CardProps } from '@mantine/core';

import * as s from './CardContainer.module.css';

interface CardContainerProps extends React.PropsWithChildren, CardProps {}

export function CardContainer(props: CardContainerProps) {
  const { children, ...rest } = props;
  return (
    <Card className={s.CardContainer} {...rest}>
      {children}
    </Card>
  );
}
