import { Context, useContext } from 'react';

export const useContextSafe = <TContext>(context: Context<TContext>): NonNullable<TContext> => {
  const ctxValue = useContext(context);
  if (!ctxValue) {
    throw new Error(
      `Cannot read ${context.displayName} context. You may have forgot to wrap it with the Provider`,
    );
  }
  return ctxValue as NonNullable<TContext>;
};
