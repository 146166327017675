import * as React from 'react';
import { Group, MantineFontSize, Text } from '@mantine/core';

import * as s from './Logo.module.css';

interface LogoProps {
  size?: MantineFontSize;
  disableIcon?: boolean;
}

export const Logo = (props: LogoProps) => {
  const { size = 'sm', disableIcon } = props;
  return (
    <Group gap="xs" align="center" wrap="nowrap" className={s.logo}>
      {disableIcon ? null : <div className={s.logo_icon} />}
      <Text className={s.logo_text} fz={size}>
        <span>Jedz Zdrowo</span>
        <br />
        <span>
          Żyj{' '}
          <Text span c="primary" fw="inherit" fz="inherit">
            Różowo
          </Text>
        </span>
      </Text>
    </Group>
  );
};
