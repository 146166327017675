import * as React from 'react';
import { Badge, Stack } from '@mantine/core';

import { CardLink, Text } from 'shared/components/atoms';
import { ProductShort } from 'domain/shop/types';

const messages = {
  new: 'Nowość',
  soon: 'Wkrótce w sprzedaży',
};

interface ProductCardProps extends ProductShort {
  comingSoon?: boolean;
}

export const ProductCard = (props: ProductCardProps) => {
  const { image, price, title, url, comingSoon } = props;

  return (
    <CardLink to={url}>
      <CardLink.Section pos="relative">
        <CardLink.Image
          image={image}
          alt={title}
          h={{ base: 300, sm: 260 }}
          objectPosition="center top"
        />
        {comingSoon && (
          <Badge size="md" pos="absolute" top={8} right={8}>
            {messages.new}
          </Badge>
        )}
      </CardLink.Section>

      <Stack gap="xs" justify="space-between" h="100%" pt="md">
        <CardLink.Title fz="md" fw="normal" ta="left">
          {title}
        </CardLink.Title>
        {comingSoon ? (
          <Text fz="md" fw="bold" ff="heading" c="black" ta="right">
            {messages.soon}
          </Text>
        ) : (
          <Text fz="xl" fw="bold" ff="heading" c="black" ta="right">
            {price.format()}
          </Text>
        )}
      </Stack>
    </CardLink>
  );
};
