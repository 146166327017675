import * as React from 'react';

import { Layout, Providers } from './src/shared/components/base';

import '@mantine/core/styles.layer.css';

export const wrapRootElement = ({ element }) => {
  return <Providers>{element}</Providers>;
};

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};
