import * as React from 'react';
import {
  Container,
  ContainerProps,
  Group,
  Highlight,
  MantineColor,
  Stack,
  useMantineTheme,
} from '@mantine/core';

import { Line, Text } from 'shared/components/atoms';

interface BigHeadingProps extends ContainerProps {
  children: string;
  highlight: string;
  description: string;
  color?: MantineColor;
}

export const BigHeading = (props: BigHeadingProps) => {
  const { children, highlight, description, color = 'primary', ...rest } = props;
  const { colors } = useMantineTheme();

  return (
    <Container size="md" {...rest}>
      <Stack gap="lg">
        <Highlight
          component="h2"
          highlight={highlight}
          highlightStyles={{
            color: colors[color][8],
            background: 'transparent',
          }}
          fz={{ base: '1.5rem', xs: '2rem', md: '2.5rem' }}
          ff="heading"
          fw="bold"
          ta="center"
          lh={1.4}
        >
          {children}
        </Highlight>
        <Group justify="center">
          <Line color={color} w={120} />
        </Group>
        <Container size="sm">
          <Text ff="heading" ta="center" lh={1.6} nbsp>
            {description}
          </Text>
        </Container>
      </Stack>
    </Container>
  );
};
