import * as React from 'react';
import { Checkbox, Group, Input, Loader, Stack, ThemeIcon } from '@mantine/core';
import { IconArrowNarrowRight } from '@tabler/icons-react';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';

import { Button, Link, Text } from 'shared/components/atoms';
import appRoutes from 'shared/consts/urls';

import { cart } from '../../entities/Cart';
import { checkout } from '../../entities/Checkout';

import * as s from './CheckoutSummary.module.css';

interface CheckboxFields {
  terms_and_conditions: boolean;
  waiver: boolean;
}

export const CheckoutCheckboxes = () => {
  const [isRedirecting, setRedirect] = React.useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CheckboxFields>();

  const handleCheckout = () => {
    setRedirect(true);
    checkout.redirectToCheckout();
  };

  return cart.isEmpty ? null : (
    <form onSubmit={handleSubmit(handleCheckout)}>
      <Stack>
        <Input.Wrapper>
          <Checkbox
            {...register('terms_and_conditions', {
              required: 'Zaakceptuj regulamin i politykę prywatności.',
            })}
            error={errors.terms_and_conditions?.message}
            label={
              <Text c="black" fz="sm" lh={1.4}>
                Rozumiem i akceptuję{' '}
                <Text component={Link} to={appRoutes.terms.compose()} td="underline" inherit>
                  regulamin
                </Text>{' '}
                oraz{' '}
                <Text component={Link} to={appRoutes.policy.compose()} td="underline" inherit>
                  politykę prywatności
                </Text>
                .&nbsp;
                <Text span c="red" fw="bold" inherit>
                  *
                </Text>
              </Text>
            }
          />
        </Input.Wrapper>

        <Input.Wrapper>
          <Checkbox
            {...register('waiver', { required: 'Zgoda jest wymagana.' })}
            error={errors.waiver?.message}
            label={
              <Text fz="sm" lh={1.4}>
                Wyrażam zgodę na spełnienie przez Usługodawcę świadczenia obejmującego dostarczanie
                treści cyfrowych, które nie są zapisane na nośniku materialnym, przed upływem
                terminu do odstąpienia od umowy (tj. przed upływem 14 dni od dnia zawarcia umowy).
                Przyjmuję do wiadomości, że spełnienie świadczenia po wyrażeniu zgody powoduje
                utratę prawa do odstąpienia od umowy.&nbsp;
                <Text span c="red" fw="bold" fz="inherit" lh="inherit">
                  *
                </Text>
              </Text>
            }
          />
        </Input.Wrapper>
      </Stack>

      <Group justify="center" py="xl">
        <Button
          type="submit"
          center
          className={clsx({ [s.CheckoutButton_fadeout]: isRedirecting })}
        >
          <span>Przejdź do płatności</span>
          <ThemeIcon size="sm" c="inherit">
            <IconArrowNarrowRight />
          </ThemeIcon>
        </Button>
        <Stack
          gap="xs"
          align="center"
          justify="center"
          className={clsx(s.RedirectiongInfo, { [s.RedirectiongInfo_fadein]: isRedirecting })}
        >
          <Text c="primary">Przekierowuję do płatności</Text>
          <Loader color="primary" size="sm" />
        </Stack>
      </Group>
    </form>
  );
};
