import * as React from 'react';
import { Group, SimpleGrid } from '@mantine/core';

import { Button, Link } from 'shared/components/atoms';
import { OpinionCard } from 'shared/components/molecules';
import appRoutes from 'shared/consts/urls';
import { Opinion } from 'domain/shop/types';

interface TestimonialsProps {
  opinions: Opinion[];
  disableMoreButton?: boolean;
  children?: React.ReactNode;
}

export const Testimonials = (props: TestimonialsProps) => {
  const { disableMoreButton, opinions, children } = props;

  return (
    <>
      <SimpleGrid cols={{ base: 1, sm: 2, lg: 3 }} spacing="lg">
        {children}
        {opinions.map((opinion) => (
          <OpinionCard key={opinion.id} {...opinion} />
        ))}
      </SimpleGrid>
      {disableMoreButton ? null : (
        <Group pt={48} justify="center">
          <Button component={Link} to={appRoutes.opinions.index.compose()} c="white">
            Więcej opinii
          </Button>
        </Group>
      )}
    </>
  );
};
