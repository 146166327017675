import * as React from 'react';
import { useLocation } from '@reach/router';

import { useContextSafe } from 'shared/hooks';

import { ProductOption } from '../../types';
import { ProductVariant as ProductVariantVO } from '../../valueObjects/ProductVariant';
import { ProductVariantButton } from './ProductVariantButton';
import { ProductVariantPrice } from './ProductVariantPrice';
import { ProductVariantSelect } from './ProductVariantSelect';

interface SelectedOption {
  name: string;
  value: string;
}

interface ProductVariantContext {
  variants: ProductVariantVO[];
  options: ProductOption[];
  selectedOptions: SelectedOption[];
  selectOption: (name: string) => (value: string | null) => void;
}

const ProductVariantContext = React.createContext<ProductVariantContext | undefined>(undefined);

interface ProductVariantProps {
  children: React.ReactNode;
  options: ProductOption[];
  variants: ProductVariantVO[];
}

export const ProductVariant = (props: ProductVariantProps) => {
  const { options, variants, children } = props;
  const { search } = useLocation();
  const defaultVariant = variants.find(
    (variant) => variant.id === new URLSearchParams(search).get('v'),
  );
  const [selectedOptions, setSelectedOptions] = React.useState<SelectedOption[]>(() => {
    if (defaultVariant) {
      return defaultVariant.selectedOptions;
    }
    return options.map(({ name, defaultOption }) => ({
      name,
      value: defaultOption,
    }));
  });

  const selectOption = (name: string) => (value: string | null) => {
    if (value) {
      setSelectedOptions((currentSelected) => [
        ...currentSelected.filter((option) => option.name !== name),
        { name, value },
      ]);
    }
  };

  return (
    <ProductVariantContext.Provider value={{ selectedOptions, selectOption, options, variants }}>
      {children}
    </ProductVariantContext.Provider>
  );
};

export const useProductVariantContext = () => {
  return useContextSafe(ProductVariantContext);
};

ProductVariant.Select = ProductVariantSelect;
ProductVariant.Price = ProductVariantPrice;
ProductVariant.Button = ProductVariantButton;
