import * as React from 'react';
import { Link } from 'gatsby';
import { em, Group, Pagination as MtPagination } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useLocation } from '@reach/router';
import { IconArrowLeft, IconArrowRight } from '@tabler/icons-react';

import * as s from './Pagination.module.css';

const resolvePathname = (pathname: string) => {
  const pageNumberIndex = pathname.search(/\/\d+/);
  if (pageNumberIndex < 0) {
    return pathname;
  } else {
    return pathname.substring(0, pageNumberIndex);
  }
};

const compilePath = (path: string, page: number) => {
  if (page < 2) {
    return path;
  } else {
    return `${path}/${page}`;
  }
};

const usePagination = (activePage: number) => {
  const { pathname } = useLocation();
  const path = resolvePathname(pathname);

  return {
    prevPagePath: () => compilePath(path, activePage - 1),
    nextPagePath: () => compilePath(path, activePage + 1),
    itemPagePath: (page: number) => compilePath(path, page),
  };
};

interface PaginationProps {
  total: number;
  activePage: number;
}

export function Pagination(props: PaginationProps) {
  const { total, activePage, ...rest } = props;
  const isMobile = useMediaQuery(`(max-width: ${em('700px')})`);
  const { itemPagePath, nextPagePath, prevPagePath } = usePagination(activePage);

  return (
    <MtPagination.Root
      total={total}
      value={activePage}
      {...rest}
      getItemProps={(page) => ({
        component: Link,
        to: itemPagePath(page),
        className: s.Pagination_control,
      })}
      size={isMobile ? 'md' : 'lg'}
    >
      <Group gap="xxs">
        {activePage === 1 ? (
          <MtPagination.Previous icon={IconArrowLeft} className={s.Pagination_control} />
        ) : (
          <MtPagination.Previous
            component={Link}
            to={prevPagePath()}
            icon={IconArrowLeft}
            className={s.Pagination_control}
          />
        )}
        <MtPagination.Items />
        {activePage === total ? (
          <MtPagination.Next icon={IconArrowRight} className={s.Pagination_control} />
        ) : (
          <MtPagination.Next
            component={Link}
            to={nextPagePath()}
            icon={IconArrowRight}
            className={s.Pagination_control}
          />
        )}
      </Group>
    </MtPagination.Root>
  );
}
