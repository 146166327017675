import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Group, rem, Stack, ThemeIcon } from '@mantine/core';

import { OFFER_OVERVIEW } from 'content/offer-overview';
import { Button, ContentContainer, Link, Section } from 'shared/components/atoms';
import { SectionTitle, TileLink } from 'shared/components/molecules';
import appRoutes from 'shared/consts/urls';

import * as s from './OfferPromote.module.css';

const messages = {
  subline: 'Oferta',
  title: 'Pomogę Ci przejść na zdrową i smaczną dietę',
  alt: 'Dietetyk Patrycja Dziub',
  cta: 'Dowiedz się więcej',
};

export const OfferPromote = () => {
  return (
    <Section pb={rem(112)}>
      <ContentContainer bg="white">
        <div className={s.OfferPromote_title}>
          <SectionTitle subline={messages.subline} title={messages.title} />
        </div>

        <div className={s.OfferPromote}>
          <div className={s.OfferPromote_layout}>
            <div className={s.OfferPromote_image}>
              <StaticImage
                src="../../../../assets/images/pati_offer.png"
                alt={messages.alt}
                placeholder="none"
              />
            </div>
            <Stack className={s.OfferPromote_cards}>
              {OFFER_OVERVIEW.map((offer) => (
                <TileLink
                  key={offer.url}
                  variant="compact"
                  showShadow
                  {...offer}
                  icon={<ThemeIcon>{offer.icon}</ThemeIcon>}
                  className="offer_promote_link"
                />
              ))}

              <Group justify="center">
                <Button
                  component={Link}
                  to={appRoutes.offer.collab.compose()}
                  c="white"
                  className="offer_promote_cta"
                >
                  {messages.cta}
                </Button>
              </Group>
            </Stack>
          </div>
        </div>
      </ContentContainer>
    </Section>
  );
};
