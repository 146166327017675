import * as React from 'react';
import { Box, Card, Divider, Group, Title } from '@mantine/core';

import { Text } from 'shared/components/atoms';

import { CheckoutSession } from '../../types';
import { CheckoutCheckboxes } from './CheckoutCheckboxes';

const messages = {
  title: 'Podsumowanie',
  subtotal: 'Wartość produktów',
  total: 'Do zapłaty',
  couponInfo: 'Jeśli posiadasz kod rabatowy, możesz go zastosować w następnym kroku.',
  maintananceBreak:
    'Trwa przerwa techniczna. W tym momencie nie można dokonać zakupu. Za utrudnienia przepraszamy. Spróbuj wrócić za chwilę.',
};

const isMaintanance = false;

interface CheckoutSummaryProps {
  checkoutSession: CheckoutSession;
}

export const CheckoutSummary = (props: CheckoutSummaryProps) => {
  const { checkoutSession } = props;

  return (
    <Card bg="gray.1" radius="md" px="md" py="lg">
      <Title order={3} size="h4" pb="sm">
        {messages.title}
      </Title>
      <Text c="gray.8" fz="sm">
        <Group component="span" justify="space-between" pb="sm">
          <span>{messages.subtotal}</span>
          <span>{checkoutSession.productsSubtotalPrice.format()}</span>
        </Group>
      </Text>
      <Text fz="xl" fw="bold" ff="heading">
        <Group component="span" justify="space-between" pb="lg">
          <span>{messages.total}</span>
          <span>{checkoutSession.totalPrice.format()}</span>
        </Group>
      </Text>
      <Text c="gray.8" fz="xs" nbsp>
        {messages.couponInfo}
      </Text>
      <Divider color="gray.3" my="md" />
      <Box pos="relative">
        {isMaintanance ? (
          <Text c="red" pt="md">
            {messages.maintananceBreak}
          </Text>
        ) : (
          <CheckoutCheckboxes />
        )}
      </Box>
    </Card>
  );
};

export const CheckoutSummarySkeleton = () => {
  return (
    <Card bg="gray.1" radius="md" px="md" py="lg">
      <Title order={3} size="h4" pb="lg">
        {messages.title}
      </Title>
    </Card>
  );
};

CheckoutSummary.Skeleton = CheckoutSummarySkeleton;
