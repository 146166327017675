import * as React from 'react';
import { GatsbyLinkProps, Link as GatsbyLink } from 'gatsby';
import { Anchor, AnchorProps } from '@mantine/core';
import clsx from 'clsx';

import * as s from './Link.module.css';

export interface LinkProps<TState>
  extends Omit<AnchorProps, 'style'>,
    React.PropsWithChildren<Omit<GatsbyLinkProps<TState>, 'ref' | 'color' | 'style'>> {}

export const Link = <TState,>(props: LinkProps<TState>) => {
  const { children, to, className, ...rest } = props;
  const isExternal = to.startsWith('http') || to.startsWith('mailto');

  return isExternal ? (
    <OutboundLink href={to} rel="noreferrer noopener" className={className} {...rest}>
      {children}
    </OutboundLink>
  ) : (
    <Anchor
      component={GatsbyLink}
      to={to}
      className={clsx(className, s.Link)}
      underline="never"
      {...rest}
    >
      {children}
    </Anchor>
  );
};

// Copied from gatsby-plugin-google-gtag v5.10.0
// https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby-plugin-google-gtag/src/index.js
const OutboundLink = React.forwardRef<
  HTMLAnchorElement,
  React.PropsWithChildren<React.AnchorHTMLAttributes<HTMLAnchorElement>>
>(({ children, ...props }, ref) => (
  <a
    ref={ref}
    {...props}
    onClick={(e) => {
      if (typeof props.onClick === 'function') {
        props.onClick(e);
      }
      let redirect = true;
      if (
        e.button !== 0 ||
        e.altKey ||
        e.ctrlKey ||
        e.metaKey ||
        e.shiftKey ||
        e.defaultPrevented
      ) {
        redirect = false;
      }
      if (props.target && props.target.toLowerCase() !== '_self') {
        redirect = false;
      }
      if (window.gtag) {
        window.gtag('event', 'click', {
          event_category: 'outbound',
          event_label: props.href,
          transport_type: redirect ? 'beacon' : '',
          event_callback: function () {
            if (redirect && props.href) {
              document.location = props.href;
            }
          },
        });
      } else {
        if (redirect && props.href) {
          document.location = props.href;
        }
      }

      return false;
    }}
  >
    {children}
  </a>
));
